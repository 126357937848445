import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import ProcessHeader from "@components/offer/how-we-work/process/header";
import ProcessBox from "@components/offer/how-we-work/process/box";
import ProcessCycle from "@components/offer/how-we-work/process/cycle";
import ProcessStages from "@components/offer/how-we-work/process/stages"
import ArrowSecondary from "@components/arrows/secondary";

const Wrapper = tw.div`relative`;

const BackgroundColor = tw.div`
  mb-68 pt-40 px-10 pb-30 bg-lightRed
  md:relative md:-left-575 md:min-w-1920
  lg:-left-450 lg:mb-150 lg:pt-95 lg:pb-85
  xl:-left-320
`;

const Container = tw.div`
  container flex-wrap mx-auto
  md:flex md:px-20
  xl:px-0
  xxl:max-w-1280!
`;

const ArrowWrapper = styled.div`
  ${tw`
    absolute bottom-200 -right-30 w-100
    xl:-bottom-212 xl:-right-174 xl:w-175
  `}

  svg {
    ${tw`static! w-full! rotate-0!`}
  }
`;

const Process = ({ scroll, width }: { scroll: any, width: number }) => (
  <Wrapper>
    <ProcessHeader width={width} scroll={scroll} />
    <BackgroundColor>
      <Container>
        <ProcessStages />
        <ProcessBox />
        <ProcessCycle />
      </Container>
    </BackgroundColor>
    <ArrowWrapper>
      <ArrowSecondary />
    </ArrowWrapper>
  </Wrapper>
);

export default Process;
