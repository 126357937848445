import React from "react";
import { motion, useTransform } from "framer-motion";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";

type StepParagraphTypes = {
  title: string,
  description: string,
  width: number,
  scroll: any,
  br?: boolean
}

const Title = tw(motion.div)`
  mb-20 text-portfolioText leading-20 -tracking-024
  lg:mb-16 lg:text-lg lg:leading-32 lg:-tracking-03
`;

const Description = tw(motion.div)`
  mb-30 text-sm text-second leading-20 -tracking-020
  lg:relative lg:mb-50 lg:text-base lg:leading-25 lg:-tracking-026
`;

const StepParagraph = ({ title, description, width, scroll, br = false }: StepParagraphTypes) => {
  const { t } = useTranslation();
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const opacity = useTransform(scroll, [sectionStart + 50, sectionStart + 400], [0, 1]);
  const left = useTransform(scroll, [sectionStart + 50, sectionStart + 350], [100, 0]);
  const titleOpacity = useTransform(scroll, [sectionStart + 50, sectionStart + 350], [0, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <>
      <Title
        ref={Ref}
        style={ width >= 1024 && { opacity: titleOpacity }}
      >
        {t(`${title}`)}
        {br && (
          <>
            <br/>
            {t(`${title}1`)}
          </>
        )}
      </Title>
      <Description style={ width >= 1024 && { left, opacity }}>
        {t(`${description}`)}
      </Description>
    </>
  );
};

export default StepParagraph;
