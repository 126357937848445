import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";
import Img from "gatsby-image";

import HeroHeader from "@components/offer/hero/header";
import Illustration from "@components/offer/hero/illustration";

const Wrapper = tw.div`
  flex flex-wrap ml-20 mb-98
  md:ml-0
  lg:relative lg:-top-35 lg:mb-80
`;

const WrapperIllustration = tw.div`
  w-full mr-20
  md:w-1/2 md:mr-0
  lg:w-5/12
`;

const Text = styled(motion.span)`
  ${tw`
    inline-block ml-20 mr-10 text-sm text-second leading-20 -tracking-021
    md:ml-0
    lg:max-w-360 lg:text-md lg:leading-30 lg:-tracking-027 lg:opacity-0
  `}
  
  & span {
    ${tw`text-portfolioText`}
  }
`;

const ImageIllustration = tw.div`
  relative max-w-340 mx-auto mb-35
  lg:max-w-450 lg:mb-55 lg:mx-0
`;

const Image = tw(motion.div)`relative lg:-right-250 lg:opacity-0`

const Image2 = tw(motion.div)`absolute! top-0 w-full lg:-left-250 lg:opacity-0`

const Hero = ({ width }: { width: number }) => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      illustrationBlue: file(relativePath: { eq: "offer/illustrationBlue.png" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      illustrationRed: file(relativePath: { eq: "offer/illustrationRed.png" }) {
        childImageSharp {
          fluid(maxWidth: 450) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Wrapper>
      <HeroHeader width={width} />
      <WrapperIllustration>
        <ImageIllustration>
          <Image
            animate={ width >= 1024 && { right: 0, opacity: 1 }}
            transition={{
              right: { duration: .8, delay: .8 },
              opacity: { duration: .458, delay: .465, ease: 'linear' },
            }}
          >
            <Img fluid={data.illustrationRed.childImageSharp.fluid} />
          </Image>
          <Image2
            animate={ width >= 1024 && { left: 0, opacity: 1 }}
            transition={{
              left: { duration: .8, delay: .8 },
              opacity: { duration: .458, delay: .465, ease: 'linear' },
            }}
          >
            <Img fluid={data.illustrationBlue.childImageSharp.fluid} />
          </Image2>
          <Illustration />
        </ImageIllustration>
        <Text
          animate={ width >= 1024 && { opacity: 1 }}
          transition={{ duration: .542, delay: 1, ease: [.88, .14, .12, .86] }}
        >
          {t('Offer:More1')}
          <span>{t('Offer:More2')}</span>
          {t('Offer:More3')}
        </Text>
      </WrapperIllustration>
    </Wrapper>
  );
};

export default Hero;
