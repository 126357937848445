import React from "react";
import { useTranslation } from "react-i18next";
import Logo from "@images/dajemyslowo.svg";
import styled from "styled-components";
import tw from "twin.macro";

const Box = tw.div`
  w-full pt-20 px-20 pb-5 bg-white rounded-lg
  md:order-6
  xl:order-4 xl:flex xl:items-center xl:w-10/12 xl:my-52 xl:-ml-30 xl:py-38 xl:px-95
`;

const BoxText = styled.div<{ textRight: boolean }>`
  ${tw`mb-25 text-sm text-second leading-18 -tracking-021 xl:mb-0`}
  
  ${({ textRight }) => textRight ? tw`text-right` : ''}
  
  span {
    ${tw`text-portfolioText`}
  }
`;

const StyledLogo = tw(Logo)`w-208 mx-auto`;

const ProcessBox = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <BoxText>
        <span>{t('Offer:Process:Communication1')}</span>
        <br/>
        {t('Offer:Process:Communication2')}
      </BoxText>
      <StyledLogo />
      <BoxText textRight>
        <span>{t('Offer:Process:Comments1')}</span>
        <br/>
        {t('Offer:Process:Comments2')}
        <br/>
        {t('Offer:Process:Comments3')}
      </BoxText>
    </Box>
  );
};

export default ProcessBox;
