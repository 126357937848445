import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import StepParagraph from "@components/offer/cooperation-details/step-paragraph";
import ArrowPrimary from "@components/arrows/primary";
import DetailsLarge from "@components/svg/details-large";

const Wrapper = tw.div`flex flex-wrap`;

const ParagraphWrapper = tw.div`w-full sm:w-1/2 sm:pr-58 lg:pr-100`;

const ArrowWrapper = styled.div`
  ${tw`
    hidden
    md:block md:absolute md:-bottom-50 md:-right-20 md:w-200
    lg:bottom-200 lg:-right-350 lg:w-275
    xl:-right-530 xl:-bottom-120
  `}

  svg {
    ${tw`static! w-full! rotate-0!`}
  }
`;

const DetailsWrapper = tw.div`
  absolute -bottom-510 -right-90 w-140 transform -scale-x-100
  md:-bottom-780 md:-right-160 md:w-250
  lg:-bottom-920 lg:-right-510 lg:w-330
  xl:-right-670
`;

const Paragraphs = ({ scroll, width }: { scroll: any, width: number }) => (
  <>
    <ArrowWrapper>
      <ArrowPrimary />
    </ArrowWrapper>
    <Wrapper>
      <ParagraphWrapper>
        <StepParagraph
          width={width}
          scroll={scroll}
          title={'Offer:Steps:Naming:Creation'}
          description={'Offer:Steps:Naming:Creation content'}
        />
      </ParagraphWrapper>
      <ParagraphWrapper>
        <StepParagraph
          width={width}
          scroll={scroll}
          title={'Offer:Steps:Naming:Verification'}
          description={'Offer:Steps:Naming:Verification content'}
        />
      </ParagraphWrapper>
      <ParagraphWrapper>
        <StepParagraph
          br
          width={width}
          scroll={scroll}
          title={'Offer:Steps:Naming:Five proposals'}
          description={'Offer:Steps:Naming:Five proposals content'}
        />
      </ParagraphWrapper>
      <ParagraphWrapper>
        <StepParagraph
          br
          width={width}
          scroll={scroll}
          title={'Offer:Steps:Naming:Final proposal'}
          description={'Offer:Steps:Naming:Final proposal content'}
        />
      </ParagraphWrapper>
    </Wrapper>
    <DetailsWrapper>
      <DetailsLarge />
    </DetailsWrapper>
  </>
);

export default Paragraphs;
