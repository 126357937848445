import React, { ReactNode, useState } from "react";
import AnimateHeight from "react-animate-height";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import tw from "twin.macro";

import Heading from "@components/offer/cooperation-details/step-heading";
import Accordion from "@components/offer/cooperation-details/step-accordion";
import Background from "@components/offer/cooperation-details/step-background";

type PointProps = {
  children: ReactNode,
  option: Record<string, string>,
  index: number,
  width: number,
  scroll: any
};

type HeightValueTypes = 70 | 'auto';

const Wrapper = styled(AnimateHeight)`
  ${up('md')} {
    height: auto !important;
    overflow: initial !important;
  }
`;

const Container = tw.div`container xxl:max-w-1280!`;

const Content = tw.div`w-full lg:w-2/3 lg:order-first`;

const WrapperChildren = tw.div`relative ml-50 mr-20 lg:mx-0`;

const Step = ({ children, option, index, width, scroll }: PointProps) => {
  const [height, setHeight] = useState<HeightValueTypes>(70);

  return (
    <Wrapper duration={500} height={height}>
      <Container>
        <Accordion
          accordion={() => setHeight(height === 70 ? 'auto' : 70)}
          active={height === 'auto'}
          step={option.name}
          index={index + 1}
        />
        <Content>
          <Heading width={ width } scroll={ scroll } name={option.name} number={index + 1}/>
          <WrapperChildren className={option.customClasses}>
            {children}
          </WrapperChildren>
        </Content>
        <Background width={ width } scroll={ scroll } name={option.project} slug={option.slug} />
      </Container>
    </Wrapper>
  );
};

export default Step;
