import React from "react";
import { fullWidthBox } from "@utils/styled-components";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import tw from "twin.macro";

import StepParagraph from "@components/offer/cooperation-details/step-paragraph";
import ArrowPrimary from "@components/arrows/primary";

const Wrapper = tw.div`relative flex flex-wrap lg:-top-200`;

const ParagraphWrapper = styled.div`
  ${tw`w-full sm:w-1/2 sm:pr-58 lg:pr-100`}
  
  ${up('lg')} {
    &:first-child {
      margin-right: 50%;
    }
  }
`;

const GradientWrapper = tw(fullWidthBox)`hidden md:block h-0 -z-1`;

const Gradient = styled.div`
  background: linear-gradient(180deg, #FFFFFF 0%,
                                      #FFF5F5 100%);
  ${tw`
    absolute -top-330 w-full h-1235
    lg:-top-200
    xl:-top-350
  `}
`;

const ArrowWrapper = styled.div`
  ${tw`
    hidden
    md:block md:absolute md:bottom-40 md:-right-20 md:w-180
    lg:bottom-220 lg:-right-350 lg:w-250
    xl:-right-610
  `}

  svg {
    ${tw`static! w-full! rotate-0!`}
  }
`;

const Paragraphs = ({ scroll, width }: { scroll: any, width: number }) => (
  <>
    <GradientWrapper>
      <Gradient />
    </GradientWrapper>
    <ArrowWrapper>
      <ArrowPrimary />
    </ArrowWrapper>
    <Wrapper>
      <ParagraphWrapper>
        <StepParagraph
          width={width}
          scroll={scroll}
          title={'Offer:Steps:Communication:Communication'}
          description={'Offer:Steps:Communication:Communication content'}
        />
      </ParagraphWrapper>
      <ParagraphWrapper>
        <StepParagraph
          width={width}
          scroll={scroll}
          title={'Offer:Steps:Communication:Workshop'}
          description={'Offer:Steps:Communication:Workshop content'}
        />
      </ParagraphWrapper>
      <ParagraphWrapper>
        <StepParagraph
          width={width}
          scroll={scroll}
          title={'Offer:Steps:Communication:Personalization'}
          description={'Offer:Steps:Communication:Personalization content'}
        />
      </ParagraphWrapper>
    </Wrapper>
  </>
);

export default Paragraphs;
