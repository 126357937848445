import React from "react";
import styled from "styled-components";
import tw from "twin.macro";

import StepParagraph from "@components/offer/cooperation-details/step-paragraph";
import ArrowSecondary from "@components/arrows/secondary";
import DetailsLarge from "@components/svg/details-large";
import DetailsRedArrow from "@components/svg/details-red-arrow";

const Wrapper = tw.div`flex flex-wrap`;

const ParagraphWrapper = tw.div`w-full sm:w-1/2 sm:pr-58 lg:pr-100`;

const ArrowWraper = styled.div`
  ${tw`
    hidden
    md:block md:absolute md:bottom-0 md:-right-160 md:w-160
    lg:bottom-400 lg:-right-490
    xl:-right-620
  `}

  svg {
    ${tw`static! w-full! rotate-0! skew-x-0! skew-y-0!`}
  }
`;

const DetailsWrapper = tw.div`
  hidden transform -scale-x-100 -scale-y-100
  lg:block lg:absolute lg:-left-110 lg:-bottom-800 lg:w-330
  xl:-left-180 xl:-bottom-855
`;

const RedArrowWrapper = styled.div`
  ${tw`
    hidden transform -scale-x-100 rotate-180
    lg:block lg:absolute lg:-bottom-750 lg:-right-40 lg:w-180
    xl:-right-120
  `}
  
  g:nth-child(1), g:nth-child(2) {
    ${tw`hidden`}
  }
`;

const Paragraphs = ({ scroll, width }: { scroll: any, width: number }) => (
  <>
    <ArrowWraper>
      <ArrowSecondary />
    </ArrowWraper>
    <Wrapper>
      <ParagraphWrapper>
        <StepParagraph
          width={width}
          scroll={scroll}
          title={'Offer:Steps:Webwriting:Architecture'}
          description={'Offer:Steps:Webwriting:Architecture content'}
        />
      </ParagraphWrapper>
      <ParagraphWrapper>
        <StepParagraph
          width={width}
          scroll={scroll}
          title={'Offer:Steps:Webwriting:Copywriting'}
          description={'Offer:Steps:Webwriting:Copywriting content'}
        />
      </ParagraphWrapper>
      <ParagraphWrapper>
        <StepParagraph
          width={width}
          scroll={scroll}
          title={'Offer:Steps:Webwriting:Layouts'}
          description={'Offer:Steps:Webwriting:Layouts content'}
        />
      </ParagraphWrapper>
    </Wrapper>
    <RedArrowWrapper>
      <DetailsRedArrow />
    </RedArrowWrapper>
    <DetailsWrapper>
      <DetailsLarge />
    </DetailsWrapper>
  </>
);

export default Paragraphs;
