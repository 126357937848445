import React from "react";
import { motion, useTransform } from "framer-motion";
import { createOfferStepsId } from "@utils/helpers";
import scrollTo from 'gatsby-plugin-smoothscroll';
import { useTranslation } from "react-i18next";
import { offerSteps } from "@utils/variables";
import tw from "twin.macro";

import ArrowRed from "@components/arrows/red";

const Wrapper = tw.div`
  relative justify-between mx-20 mb-20
  md:flex
  lg:mx-0 lg:mb-205
`;

const Text = tw(motion.div)`
  max-w-300 mr-40 pt-13 text-xl leading-33 -tracking-016
  lg:relative lg:max-w-560 lg:m-0 lg:pt-0 lg:text-8xl lg:leading-80 lg:-tracking-037
`;

const ArrowWrapper = tw.div`
  absolute! top-0 -right-50 w-120
  md:relative! md:right-auto
  lg:w-262 lg:mt-26
`;

const List = tw(motion.ul)`
  hidden mt-20
  md:block
  lg:mt-28 lg:mr-60
`;

const Item = tw.li`mb-18 text-second leading-30 cursor-pointer`;

const Number = tw.span`font-pangramBold mr-35 -tracking-018`;

const Name = tw.span`leading-30 -tracking-024`;

const Header = ({ scroll, width }: { scroll: any, width: number }) => {
  const { t } = useTranslation();
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const y = useTransform(scroll, [sectionStart + 150, sectionStart + 400], [150, 0]);
  const rotate = useTransform(scroll, [sectionStart + 150, sectionStart + 400], [-9, 0]);
  const opacity = useTransform(scroll, [sectionStart + 150, sectionStart + 400], [0, 1]);

  const menuOpacity = useTransform(scroll, [sectionStart + 200, sectionStart + 500], [0, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref}>
      <Text style={ width >= 1024 && { y, opacity, rotate }}>
        {t('Offer:Details')}
      </Text>
      <ArrowWrapper>
        <ArrowRed />
      </ArrowWrapper>
      <List style={ width >= 1024 && { opacity: menuOpacity }}>
        {offerSteps.map((item, index) => (
          <Item onClick={() => scrollTo(createOfferStepsId(item.name))} key={item.name}>
            <Number>
              {index + 1}
            </Number>
            <Name>
              {t(`Offer:Options:${item.name}`)}
            </Name>
          </Item>
        ))}
      </List>
    </Wrapper>
  );
};

export default Header;
