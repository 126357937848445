import React from "react";

import CommunicationColumns from "@components/offer/cooperation-details/steps/communication/columns";
import CommunicationParagraphs from "@components/offer/cooperation-details/steps/communication/paragraphs";
import WebwritingColumns from "@components/offer/cooperation-details/steps/webwriting/columns";
import WebwritingParagraphs from "@components/offer/cooperation-details/steps/webwriting/paragraphs";
import NamingColumns from "@components/offer/cooperation-details/steps/naming/columns";
import NamingParagraphs from "@components/offer/cooperation-details/steps/naming/paragraphs";

const components: any = {
  CommunicationColumns: CommunicationColumns,
  CommunicationParagraphs: CommunicationParagraphs,
  WebwritingColumns: WebwritingColumns,
  WebwritingParagraphs: WebwritingParagraphs,
  NamingColumns: NamingColumns,
  NamingParagraphs: NamingParagraphs
};

const StepContent = ({ name, width, scroll }: { name: string, width: number, scroll: any }) => {
  const Columns = components[`${name}Columns`];
  const Paragraphs = components[`${name}Paragraphs`];

  return (
    <>
      <Columns width={width} scroll={scroll} />
      <Paragraphs width={width} scroll={scroll} />
    </>
  )
};

export default StepContent;
