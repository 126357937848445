import React from "react";
import { motion, useTransform } from "framer-motion";
import { useTranslation } from "react-i18next";
import tw from "twin.macro";
import styled from "styled-components"

const Header = tw.div`
  justify-between pl-30 pr-50
  md:flex md:px-0
`;

const Title = tw.div`
  relative mb-10 leading-33 -tracking-012
  md:text-2xl
  lg:mb-0 lg:text-4xl lg:leading-60 lg:-tracking-028
`;

const ShortDesc = tw(motion.div)`
  max-w-300 mb-20 text-sm text-second leading-20 -tracking-021
  md:max-w-400 md:mt-12 md:text-md md:leading-30 md:-tracking-027
  lg:relative lg:mb-35
  xl:mr-85
`;

const Mask = styled(motion.span)`
  ${tw`hidden lg:block lg:absolute lg:inset-0 lg:bg-white`}
`;

const ProcessHeader = ({ scroll, width }: { scroll: any, width: number }) => {
  const { t } = useTranslation();
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const left = useTransform(scroll, [sectionStart + 50, sectionStart + 500], [0, 200]);
  const right = useTransform(scroll, [sectionStart + 50, sectionStart + 400], [-120, 0]);
  const opacity = useTransform(scroll, [sectionStart + 50, sectionStart + 400], [0, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Header ref={Ref}>
      <Title>
        {t('Offer:Process:Title')}
        <Mask style={ width >= 1024 && { left } } />
      </Title>
      <ShortDesc style={ width >= 1024 && { right, opacity } }>
        {t('Offer:Process:Description')}
      </ShortDesc>
    </Header>
  );
};

export default ProcessHeader;
