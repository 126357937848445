import React from "react";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import tw from "twin.macro";
import Img from "gatsby-image";

import StepColumn from "@components/offer/cooperation-details/step-column";
import List from "@components/offer/cooperation-details/steps/webwriting/list";
import ArrowPrimary from "@components/arrows/primary";

const ColumnsWrapper = tw.div`relative flex flex-wrap`;

const WrapperContentLighter = tw(motion.div)`w-full mb-26 xl:pr-110`;

const WrapperContent = tw(motion.div)`
  w-full mb-90 md:w-1/2 lg:relative lg:pr-30 xl:whitespace-pre-line
`;

const ContentWithImage = tw(motion.div)`
  relative flex w-full ml-40 mb-45
  md:mb-75 md:pl-85
`;

const Illustration = tw(Img)`
  absolute! -top-13 -left-125 w-110
  md:-top-25 md:-left-55
`;

const ArrowWrapper = styled.div`
  top: 23%;
  ${tw`
    absolute! -right-10 w-130 transform rotate-20
    md:top-190 md:right-auto md:-left-130 md:w-200
    lg:w-260 lg:-left-200 lg:top-300
    xl:top-245
  `}

  svg {
    ${tw`static! w-full! rotate-0!`}
  }
`;

const Columns = ({ scroll, width }: { scroll: any, width: number }) => {
  const data = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "illustration-orange.png" }) {
        childImageSharp {
          fluid(maxWidth: 150) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const y = useTransform(scroll, [sectionStart + 100, sectionStart + 500], [150, 0]);
  const rotate = useTransform(scroll, [sectionStart + 100, sectionStart + 500], [-9, 0]);
  const opacity = useTransform(scroll, [sectionStart + 100, sectionStart + 500], [0, 1]);

  const stepOpacity = useTransform(scroll, [sectionStart + 50, sectionStart + 350], [0, 1]);
  const contentOpacity = useTransform(scroll, [sectionStart + 550, sectionStart + 800], [0, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <ColumnsWrapper ref={Ref}>
      <ArrowWrapper>
        <ArrowPrimary />
      </ArrowWrapper>
      <WrapperContentLighter style={ width >= 1024 && { opacity: stepOpacity }}>
        <StepColumn lighter content={'Offer:Steps:Webwriting:First Column'} />
      </WrapperContentLighter>
      <WrapperContent style={ width >= 1024 && { y, rotate, opacity }}>
        <StepColumn content={'Offer:Steps:Webwriting:Second Column'} />
      </WrapperContent>
      <List width={width} scroll={scroll} sectionStart={sectionStart} />
      <ContentWithImage style={ width >= 1024 && { opacity: contentOpacity }}>
        <Illustration fluid={data.illustration.childImageSharp.fluid} />
        <StepColumn content={'Offer:Steps:Webwriting:Third Column'} />
      </ContentWithImage>
    </ColumnsWrapper>
  );
};

export default Columns;
