import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { motion, useTransform } from "framer-motion";
import { pagesRoutes } from "@utils/variables";
import { useTranslation } from "react-i18next";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import Img from "@images/scroll.svg";
import tw from "twin.macro";

import ButtonOval from "@components/buttons/oval";
import CustomLink from "@components/link";
import { lang } from "@utils/helpers";
import { createUrl } from "@utils/caseHelpers";

type StepBackgroundProps = {
  name: string,
  slug: string,
  width: number,
  scroll: any
};

const Background = styled(BackgroundImage)`
  ${tw`
    flex flex-col justify-end h-320 mb-70 mx-20 p-20 text-white bg-center bg-no-repeat bg-cover
    rounded-lg
    md:relative md:-left-85 md:justify-between md:h-480 md:min-w-1450 md:mx-0 md:mb-160 md:pt-150
    md:pb-60 md:pl-105 md:rounded-none
    lg:mb-215
  `}
  
  &::before {
    ${tw`rounded-lg md:rounded-none`}
  }
`;

const Description = tw(motion.div)`
  mb-8 text-sm leading-22 -tracking-021
  md:text-md md:leading-30 md:mb-25
  lg:relative
`;

const Name = tw(motion.div)`
  max-w-200 mb-25 text-2xl leading-45 -tracking-024
  md:max-w-none md:text-4xl md:leading-55
  lg:relative
`;

const Project = tw.div`
  flex flex-col justify-end
  md:flex-row md:items-center md:justify-start
`;

const ProjectDetails = tw.div`flex flex-col justify-end md:mr-30`;

const StyledLink = tw(CustomLink)`flex items-center`;

const LinkArrow = styled(Img)`
  ${tw`w-8 transform -rotate-90`}
  
  .svg-color {
    stroke: #FFF;
  }
`;

const LinkText = tw.span`font-pangramBold ml-30 mr-10 -tracking-013`;

const Or = tw.span`text-sm leading-30 -tracking-021 md:text-base`;

const RequestWrapper = tw(motion.div)`flex items-center lg:relative`;

const ArrowWrapper = styled.div`
  border-color: rgba(255, 255, 255, .8);
  ${tw`flex justify-center items-center w-45 h-45 border rounded-full`}
  
  ${up('lg')} {
    svg {
      polyline {
        transform: translateY(-3px);
        transition: transform .3s;
      }
    
      line {
        transform: scaleY(0);
        transition: transform .3s;
      }
    }
    
    &:hover {
      svg {
        polyline {
          transform: translateY(-.5px);
        }
      
        line {
          transform: scaleY(1);
        }
      }
    }
   }
`;

const StepBackground = ({ name, slug, width, scroll }: StepBackgroundProps) => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      bluemedia: file(relativePath: { eq: "offer/backgrounds/bluemedia.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      eurobank: file(relativePath: { eq: "offer/backgrounds/eurobank.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      droidsonroids: file(relativePath: { eq: "offer/backgrounds/droids.png" }) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const projectImageName = name.toLocaleLowerCase().replace(/\s/g,'');
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const left = useTransform(scroll, [sectionStart + 50, sectionStart + 320], [-100, 0]);
  const opacity = useTransform(scroll, [sectionStart + 50, sectionStart + 220], [0, 1]);
  const nameLeft = useTransform(scroll, [sectionStart + 200, sectionStart + 470], [-100, 0]);
  const nameOpacity = useTransform(scroll, [sectionStart + 200, sectionStart + 370], [0, 1]);
  const btnOpacity = useTransform(scroll, [sectionStart + 200, sectionStart + 600], [0, 1]);
  const wrapperLeft = useTransform(scroll, [sectionStart + 350, sectionStart + 620], [-100, 0]);
  const wrapperOpacity = useTransform(scroll, [sectionStart + 350, sectionStart + 520], [0, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Background Tag="div" fluid={data[projectImageName].childImageSharp.fluid}>
      <Project ref={Ref}>
        <ProjectDetails>
          <Description style={ width >= 1024 && { left, opacity }}>
            {t('Show off1')}
            <br/>
            {t('Show off2')}
          </Description>
          <Name style={ width >= 1024 && { left: nameLeft, opacity: nameOpacity }}>
            { name }
          </Name>
        </ProjectDetails>
        <motion.div style={ width >= 1024 && { opacity: btnOpacity } }>
          <ButtonOval href={createUrl(slug, lang)} type='noIntl' />
        </motion.div>
      </Project>
      <RequestWrapper style={ width >= 1024 && { left: wrapperLeft, opacity: wrapperOpacity }}>
        <Or>
          {t('Offer:Backgrounds:or')}
        </Or>
        <StyledLink to={pagesRoutes.contact}>
          <LinkText>
            {t('Buttons:Send request')}
          </LinkText>
          <ArrowWrapper>
            <LinkArrow />
          </ArrowWrapper>
        </StyledLink>
      </RequestWrapper>
    </Background>
  );
};

export default StepBackground;
