import React from "react";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import { up } from "styled-breakpoints";
import styled, { keyframes } from "styled-components";
import tw from "twin.macro";
import Img from "gatsby-image";

import ArrowRed from "@components/arrows/red";
import ArrowPrimary from "@components/arrows/primary";

const illustration = keyframes`
  0% { transform: rotate(-10deg) translateX(20px) rotate(20deg) ;}
  50%{ transform: rotate(-170deg) translateX(20px) rotate(160deg);}
  100% { transform: rotate(-10deg) translateX(20px) rotate(20deg) ;}
`;

const Wrapper = tw.div`
  relative mx-30 mb-60
  md:flex md:flex-wrap
  lg:mx-0 lg:mb-280
`;

const WrapperTitle = tw.div`mb-30 md:w-1/2`;

const Title = tw.div`
  relative mb-10 text-xl leading-33 -tracking-016
  md:mb-30
  lg:mt-40 lg:mb-58 lg:text-4xl lg:leading-60 lg:-tracking-028
`;

const Description = styled(motion.div)`
  ${tw`
    mb-20 leading-24 -tracking-024
    lg:text-2xl lg:leading-48
  `}
  
  & span {
    ${tw`text-portfolioText`}
  }
  
  & br {
    ${tw`hidden xl:block`}
  }
`;

const WrapperImages = tw.div`relative h-215 mb-30 md:w-1/2`;

const Illustration = styled(Img)`
  ${tw`
    absolute! left-30 w-200
    md:left-100
    lg:w-320
    xl:left-180
  `}
  
  ${up('lg')} {
    animation: ${illustration} 2s ease-in-out infinite;
  }
`;

const Columns = tw(motion.div)`
  w-full text-second text-sm leading-20 -tracking-021
  md:flex md:flex-wrap
  lg:text-md lg:leading-30 lg:-tracking-027
`;

const ColumnFirst = styled.div`
  ${tw`mb-20 md:w-1/2 xl:whitespace-pre-line`}
  
  & br {
    ${tw`hidden lg:block`}
  }
`;

const ColumnSecond = tw.div`md:w-1/2 md:pl-60 md:pr-5 lg:pl-110`;

const Mask = styled(motion.span)`
  ${tw`hidden lg:block lg:absolute lg:inset-0 lg:bg-white`}
`;

const ArrowWrapper = styled.div`
  ${tw`
    hidden transform -scale-x-100
    xl:block xl:absolute xl:top-200 xl:-left-180 xl:w-260
  `}

  svg {
    ${tw`static! w-full! rotate-0! scale-100!`}
  }
`;

const ArrowRedWrapper = tw.div`
  absolute left-240 w-170
  md:left-auto md:-right-120
  lg:w-330 lg:-right-230
`;

const Presentation = ({ scroll, width }: { scroll: any, width: number }) => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "offer/bicycle.png" }) {
        childImageSharp {
          fluid(maxWidth: 350) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      arrow: file(relativePath: { eq: "offer/arrow.png" }) {
        childImageSharp {
          fluid(maxWidth: 320) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      arrowBlue: file(relativePath: { eq: "arrow.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const left = useTransform(scroll, [sectionStart + 50, sectionStart + 500], [0, 400]);
  const opacity = useTransform(scroll, [sectionStart + 550, sectionStart + 800], [0, 1]);

  const y = useTransform(scroll, [sectionStart + 150, sectionStart + 600], [150, 0]);
  const rotate = useTransform(scroll, [sectionStart + 150, sectionStart + 600], [-9, 0]);
  const opacityA = useTransform(scroll, [sectionStart + 150, sectionStart + 600], [0, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref}>
      <WrapperTitle>
        <Title>
          {t('Offer:Presentation:Title')}
          <Mask style={ width >= 1024 && { left }} />
        </Title>
        <Description style={ width >= 1024 && { y, rotate, opacity: opacityA }}>
          {t('Offer:Presentation:Description1')}
          <br/>
          {t('Offer:Presentation:Description2')}
          <span>{t('Offer:Presentation:Description3')}</span>
          {t('Offer:Presentation:Description4')}
        </Description>
      </WrapperTitle>
      <WrapperImages>
        <Illustration fluid={data.illustration.childImageSharp.fluid} />
        <ArrowRedWrapper>
          <ArrowRed />
        </ArrowRedWrapper>
      </WrapperImages>
      <ArrowWrapper>
        <ArrowPrimary />
      </ArrowWrapper>
      <Columns style={ width >= 1024 && { opacity }}>
        <ColumnFirst>
          {t('Offer:Presentation:Column first1')}
          <br/>
          {t('Offer:Presentation:Column first2')}
        </ColumnFirst>
        <ColumnSecond>
          {t('Offer:Presentation:Column second')}
        </ColumnSecond>
      </Columns>
    </Wrapper>
  );
};

export default Presentation;
