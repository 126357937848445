import React from "react";
import { motion, useTransform } from "framer-motion";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

const listItems = [1, 2, 3, 4, 5];

const OrderedList = tw.ol`
  relative w-full mb-50
  md:w-1/2
  lg:left-40 lg:mb-75
`;

const ListItem = tw.li`
  flex mb-10 mr-30 -tracking-021
  lg:mr-0 lg:mb-12 lg:-tracking-024
`;

const ListContent = styled(motion.p)`
  ${tw`
    text-sm text-second leading-21
    lg:relative lg:text-base lg:leading-30
  `}
  
  & br {
    ${tw`hidden xl:block`}
  }
`;

const ListNumber = tw(motion.span)`
  font-pangramBold min-w-20 text-xs text-portfolioText leading-30 -tracking-018 
  lg:relative lg:-left-30 lg:min-w-10 lg:mr-20 lg:text-lg lg:leading-32 lg:-tracking-03
`;

const List = ({ scroll, width, sectionStart }: { scroll: any, width: number, sectionStart: number }) => {
  const { t } = useTranslation();

  const left = [
    useTransform(scroll, [sectionStart + 200, sectionStart + 350], [120, 0]),
    useTransform(scroll, [sectionStart + 260, sectionStart + 410], [120, 0]),
    useTransform(scroll, [sectionStart + 320, sectionStart + 460], [120, 0]),
    useTransform(scroll, [sectionStart + 370, sectionStart + 520], [120, 0]),
    useTransform(scroll, [sectionStart + 420, sectionStart + 570], [120, 0]),
  ];
  const opacity = [
    useTransform(scroll, [sectionStart + 200, sectionStart + 400], [0, 1]),
    useTransform(scroll, [sectionStart + 260, sectionStart + 410], [0, 1]),
    useTransform(scroll, [sectionStart + 320, sectionStart + 460], [0, 1]),
    useTransform(scroll, [sectionStart + 370, sectionStart + 520], [0, 1]),
    useTransform(scroll, [sectionStart + 420, sectionStart + 570], [0, 1]),
  ];
  const numberOpacity = [
    useTransform(scroll, [sectionStart + 150, sectionStart + 300], [0, 1]),
    useTransform(scroll, [sectionStart + 210, sectionStart + 360], [0, 1]),
    useTransform(scroll, [sectionStart + 270, sectionStart + 420], [0, 1]),
    useTransform(scroll, [sectionStart + 310, sectionStart + 510], [0, 1]),
    useTransform(scroll, [sectionStart + 370, sectionStart + 580], [0, 1]),
  ];

  return (
    <OrderedList>
      {listItems.map((number) => (
        <ListItem key={number}>
          <ListNumber style={ width >= 1024 && { opacity: numberOpacity[number - 1] }}>
            { number }
          </ListNumber>
          <ListContent style={ width >= 1024 && { opacity: opacity[number - 1], left: left[number - 1] }}>
            {t(`Offer:Steps:Webwriting:List:${number}1`)}
            <br/>
            {t(`Offer:Steps:Webwriting:List:${number}2`)}
          </ListContent>
        </ListItem>
      ))}
    </OrderedList>
  );
};

export default List;
