import React from "react";
import { StickyContainer, Sticky } from "react-sticky";
import tw from "twin.macro";

import StickyNav from "@components/offer/cooperation-details/sticky-nav";

const StyledStickyContainer = tw(StickyContainer)`absolute right-0 h-full w-1/3 z-1`;

const StepStickyContainer = () => (
  <StyledStickyContainer>
    <Sticky bottomOffset={695}>
      {({ style }: { style: Record<string, string> }) => (
        <div style={style}>
          <StickyNav />
        </div>
      )}
    </Sticky>
  </StyledStickyContainer>
);

export default StepStickyContainer;
