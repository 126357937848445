import React from "react";
import styled from "styled-components";
import Img from "@images/scroll.svg";
import tw from "twin.macro";
import { useTranslation } from "react-i18next"

type StepNavigationProps = {
  step: string,
  index: number,
  active: boolean,
  accordion: Function
};

const ItemWrapper = styled.div`
  box-shadow: 0 0 4px 0 rgba(2, 2, 12, 0.1);
  ${tw`
    relative flex items-center justify-between mx-10 mb-40 py-10 px-20 text-base -tracking-024
    bg-lightBlue cursor-pointer rounded-full leading-30 z-1
    md:hidden
  `}
`;

const Number = tw.span`font-pangramBold min-w-20 text-xs -tracking-018`;

const Arrow = styled(Img)<{ active: boolean }>`
  transition: transform .5s;
  ${tw`w-10 ml-auto`}

  ${({ active }) => active && { transform: 'scale3d(-1, -1, -1)' }}
  
  .svg-color {
    stroke: #F06140;
  }
`;

const StepAccordion = ({ step, index, active, accordion }: StepNavigationProps) => {
  const { t } = useTranslation();

  return (
    <ItemWrapper onClick={accordion}>
      <Number>
        {index}
      </Number>
      {t(`Offer:Options:${step}`)}
      <Arrow active={active}/>
    </ItemWrapper>
  )
};

export default StepAccordion;
