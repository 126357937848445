import React from "react";
import { StickyContainer, Sticky } from 'react-sticky';
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { lottieOptions } from "@utils/helpers";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Lottie from "react-lottie";
import Img from "gatsby-image";
import tw from "twin.macro";

import head from '@lottie/head.json';
import mug from '@lottie/mug.json';

const Wrapper = tw.div`ml-20 sm:flex sm:justify-between lg:w-full`;

const StyledStickyContainer = tw(StickyContainer)`h-full`;

const StickyWrapper = tw.div`h-1920 mb-100 lg:h-3000 xl:mb-200`;

const LeftBox = tw(motion.div)`
  flex mb-20 pt-20 text-second text-sm -tracking-021
  sm:min-w-1/2 sm:pt-30
  lg:mb-20 lg:pt-116 lg:-tracking-027
  
  lg:h-753
`;

const RightBox = tw.div`
  relative pt-30 pl-30
  sm:min-w-950 sm:w-1/2
  lg:pt-100 lg:pl-110
  
  lg:h-753
`;

const Background = tw(motion.div)`
  absolute inset-0 bg-lightBlue -z-1
`;

const Copywriter = styled.h2`
  ${tw`
    max-w-240 leading-20
    lg:max-w-450 lg:text-md lg:leading-30
  `}

  & br {
    ${tw`lg:hidden`}
  }
`;

const CopywriterLetter = tw.span`
  font-pangramBold ml-4 mr-20 leading-32
  lg:text-lg lg:-tracking-03`;

const Communication = tw(motion.div)`
  max-w-240 mb-30 -tracking-013 text-md leading-25
  lg:max-w-410 lg:mb-170 lg:text-2xl lg:leading-51 lg:-tracking-024
`;

const HugeTextWrapper = tw(motion.div)`
  relative -left-440 w-1280 flex ml-70 pl-440 pb-140 text-orange text-3xl leading-40 -tracking-027
  sm:-left-30 sm:ml-0 sm:pl-150 sm:overflow-hidden
  lg:-left-440 lg:ml-0 lg:pl-440 lg:pb-240 lg:-tracking-067
`;

const HugeTextNumber = tw.span`font-pangramBold mr-30 text-base -tracking-013 lg:leading-12`;

const HugeText = tw.div`
  mr-80 whitespace-no-wrap
  lg:text-10xl lg:leading-90 lg:whitespace-no-wrap
  lg:mr-350
`;

const ImageIllustration = tw(motion.div)`
  absolute! left-8 w-168 -bottom-55
  md:-left-20
  lg:-bottom-90 lg:-left-370 lg:w-470 lg:z-1
`;

const StyledSVG = tw.svg`absolute top-0 overflow-visible`;

const LootieWrapper = styled.div`
  ${tw`absolute top-0`}
  
  div {
    ${tw`cursor-default`}
  }
`;

const HeroMore = ({ scroll, width }: { scroll: any, width: number }) => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      imageIllustration: file(relativePath: { eq: "home-page/illustration.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const RefWhoWeAre = React.useRef();

  const [scrollPercentageStart, setScrollPercentageStart] = React.useState(0);
  const [scrollPercentageHalf, setScrollPercentageHalf] = React.useState(.5);
  const [scrollPercentageEnd, setScrollPercentageEnd] = React.useState(1);

  const y = useTransform(scroll, [scrollPercentageStart, scrollPercentageStart + 500], [400, 0]);
  const ySmall = useTransform(scroll, [scrollPercentageStart, scrollPercentageHalf], [200, 0]);

  const yIllustration = useTransform(scroll, width >= 1024 ? [scrollPercentageStart, scrollPercentageHalf] : [scrollPercentageStart + 600, scrollPercentageStart + 800], width >= 1024 ? [520, 0] : [200, 0]);
  const xIllustration = useTransform(scroll, width >= 1024 ? [scrollPercentageStart + 700, scrollPercentageStart + 1000] : [scrollPercentageEnd + 300, scrollPercentageEnd + 700], [0, -250]);

  const x2 = useTransform(scroll, [scrollPercentageStart + 250, scrollPercentageStart + 700, width >= 1024 ? scrollPercentageEnd + 300 : scrollPercentageEnd + 500], width >= 1024 ? [800, 0, -3850] : [280, 0, -1500]);
  const rotate = useTransform(scroll, [scrollPercentageStart, scrollPercentageHalf], [-9, 0]);
  const opacity = useTransform(scroll, [scrollPercentageStart, scrollPercentageHalf], [0, 1]);
  const bgY = useTransform(scroll, [scrollPercentageStart, scrollPercentageStart + 500], [600, 0]);
  const pathLength = useTransform(scroll, [scrollPercentageHalf + 400, scrollPercentageHalf + 960], [0, 1]);
  const pathOpacity = useTransform(scroll, [scrollPercentageHalf + 400, scrollPercentageHalf + 960], [0, 1]);
  const polygonScale = useTransform(scroll, [scrollPercentageHalf + 400, scrollPercentageHalf + 600, scrollPercentageHalf + 960], [0, -.4, 1]);

  React.useLayoutEffect(() => {
    if (!RefWhoWeAre.current) return;

    const offsetStart = RefWhoWeAre.current.offsetTop - window.innerHeight;
    const offsetEnd = offsetStart + RefWhoWeAre.current.offsetHeight;
    const half = offsetStart + 300;

    setScrollPercentageStart(offsetStart);
    setScrollPercentageHalf(half);
    setScrollPercentageEnd(offsetEnd);
  });

  return (
    <StickyWrapper ref={RefWhoWeAre}>
      <StyledStickyContainer>
        <Sticky>{({ style }: { style: Record<string, string> }) => (
          <Wrapper style={ style }>
            <LeftBox style={ width >= 1024 && { y }}>
              <CopywriterLetter>
                {t('Home page:Short')}
              </CopywriterLetter>
              <Copywriter>
                {t('Home page:Copywriter')}
              </Copywriter>
            </LeftBox>
            <RightBox>
              <Background style={ width >= 1024 && { y: bgY }} />
              <Communication style={ width >= 1024 && { y: ySmall, opacity, rotate }}>
                {t('Home page:Communication')}
              </Communication>
              <ImageIllustration style={{ y: yIllustration, x: xIllustration }}>
                <Img fluid={data.imageIllustration.childImageSharp.fluid} />
                <StyledSVG viewBox="0 0 471 499">
                  <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g transform="translate(8.000000, 1.000000)">
                      <g stroke="#761113" strokeWidth="0.25" strokeLinejoin="round">
                        <motion.path style={ width >= 1024 && { pathLength }} d="M40.7926122,350.112264 C40.0510536,344.226656 39.6782482,338.268236 39.6782482,332.24914 C39.6782482,294.395173 54.6147784,258.980397 80.5976952,228.727158" />
                        <motion.path style={ width >= 1024 && { pathLength }} d="M29.6234428,363.049275 C27.8039903,354.979318 26.7058352,346.868909 26.2925075,338.746366 C25.0038974,313.262289 30.4946729,287.717536 41.7355668,263.244733" />
                        <motion.path style={ width >= 1024 && { pathLength }} d="M336.613713,487.210527 C236.908524,509.624379 141.142106,493.035459 78.8795485,449.562861" />
                        <motion.path style={ width >= 1024 && { pathLength }} d="M303.788196,363.187618 C320.925088,368.502868 347.118721,372.321434 378.924697,363.050084" />
                        <motion.path style={ width >= 1024 && { pathLength }} d="M369.771513,20.3127176 C387.560815,24.5196127 402.343361,32.0515731 411.728332,44.1787574" />
                        <motion.path style={ width >= 1024 && { pathLength }} d="M393.748575,19.2739381 C410.245215,27.1375959 423.098897,37.6224731 429.708089,51.452641" />
                        <motion.path style={ width >= 1024 && { pathLength }} d="M411.730358,339.70303 C416.260754,336.632805 419.28372,333.789106 421.119382,331.802966" />
                        <motion.path style={ width >= 1024 && { pathLength }} d="M235.155528,306.238392 C249.087105,322.333811 285.565305,357.360259 339.135823,357.360259 C373.044907,357.360259 394.343443,349.89302 407.047193,342.619945" />
                        <motion.path style={ width >= 1024 && { pathLength }} d="M228.406535,484.157292 C191.697357,482.001259 157.472199,473.959617 127.817959,460.792844" />
                        <motion.line style={ width >= 1024 && { opacity: pathOpacity }} x1="244.179851" y1="477.808926" x2="263.707561" y2="477.861512" />
                        <motion.line style={ width >= 1024 && { opacity: pathOpacity }} x1="236.631757" y1="484.370469" x2="259.802424" y2="483.520999" />
                      </g>
                      <motion.polygon style={ width >= 1024 && { scale: polygonScale }} id="Fill-157" fill="#EE3F2E" points="280.286056 479.99732 255.563383 469.892682 253.160409 491.02424"></motion.polygon>
                      <motion.polygon style={ width >= 1024 && { scale: polygonScale }} fill="#00A1D6" points="380.74496 358.225908 388.419889 368.48426 400.973706 353.493151"></motion.polygon>
                      <path d="M367.761201,113.983688 C363.56714,113.983688 360.151107,117.389655 360.151107,121.58037 C360.151107,125.767039 363.56714,129.173007 367.761201,129.173007 C371.959314,129.173007 375.371294,125.767039 375.371294,121.58037 C375.371294,117.389655 371.959314,113.983688 367.761201,113.983688 M367.761201,156.780756 C348.318587,156.780756 332.498671,140.988719 332.498671,121.58037 C332.498671,102.167976 348.318587,86.3759384 367.761201,86.3759384 C387.207866,86.3759384 403.027783,102.167976 403.027783,121.58037 C403.027783,140.988719 387.207866,156.780756 367.761201,156.780756" id="Fill-133" fill="#FCC89A"></path>
                      <path d="M356.522738,135.032726 C351.117059,133.495591 354.662763,132.144531 353.143176,127.088166 C352.429983,124.717743 351.117059,123.548711 348.754608,123.382863 C346.675812,123.233194 346.266537,120.114429 348.23187,118.455941 C352.385408,114.952892 350.42818,115.08638 351.777573,111.716819 C353.499772,107.416886 357.685729,114.580743 359.711845,110.030015 C360.834314,107.509923 360.651963,104.24149 362.601087,103.493148 C366.989655,101.806344 368.010818,104.726901 369.096816,106.154818 C371.532208,109.354485 373.22199,110.766222 376.597499,109.13605 C379.973009,107.501833 382.505655,109.512244 381.66279,113.901168 C380.819926,118.290092 381.326455,121.323911 384.195436,120.142744 C387.064417,118.961578 387.74114,121.829547 385.885217,126.885912 C384.025242,131.942276 386.719977,132.35083 379.73798,133.325697 C376.107179,133.831334 378.554728,147.58869 369.348055,137.334383 C365.976597,133.576493 363.991003,137.160444 356.522738,135.032726 M401.291806,132.431732 C402.40617,129.009585 403.030214,125.369002 403.030214,121.578752 C403.030214,102.166358 387.206245,86.3783655 367.75958,86.3783655 C348.321019,86.3783655 332.49705,102.166358 332.49705,121.578752 C332.49705,138.766346 344.913091,153.09406 361.255746,156.156194 L361.231433,156.168329 C361.231433,156.168329 361.482671,156.20069 361.851424,156.245186 C362.710498,156.390809 363.573623,156.532387 364.452958,156.613289 C366.353455,156.904536 368.865839,157.345451 371.378224,157.931989 C378.59525,159.622837 390.1198,163.158247 391.132859,158.437625 C391.971671,154.546247 389.779413,150.23418 391.323314,147.693862 C391.82579,147.248902 392.33232,146.807987 392.802379,146.330667 C392.814535,146.326622 392.818587,146.318531 392.830744,146.310441 C397.045066,144.279805 400.805538,146.609778 400.084241,142.091411 C399.435884,138.042274 400.850113,133.665485 401.291806,132.431732" id="Fill-135" fill="#EBA2C7"></path>
                      <path d="M397.65047,162.821291 C397.950335,165.834884 405.151153,168.719035 407.84994,166.866383 C410.552779,165.013731 409.539721,161.134488 406.500546,160.798745 C403.461372,160.463003 397.237142,158.7762 397.65047,162.821291" id="Fill-137" fill="#EBA2C7" />
                      <path d="M398.900989,150.853888 C398.674064,152.783397 402.15088,154.0576 403.524587,152.961381 C404.894241,151.865161 403.946019,150.518145 403.030214,149.927562 C402.110357,149.336979 399.071183,149.421926 398.900989,150.853888" id="Fill-139" fill="#EBA2C7" />
                      <path d="M382.125961,99.310523 C379.775666,97.5751788 377.129558,96.2241182 374.276786,95.3463333 M366.561334,94.1894372 C356.888655,94.1894372 348.42354,99.444011 343.880987,107.242947 C341.627946,111.10601 340.335284,115.587971 340.335284,120.365224" id="Stroke-141" stroke="#FEFEFE" strokeLinecap="round"></path>
                      <line x1="374.314471" y1="152.789464" x2="383.62245" y2="155.070896" id="Stroke-143" stroke="#FEFEFE" strokeLinecap="round"></line>
                      <line x1="394.678562" y1="107.276117" x2="392.482252" y2="116.159138" id="Stroke-159" stroke="#761113" strokeWidth="0.25" strokeLinejoin="round"></line>
                      <line x1="338.790167" y1="107.276117" x2="341.638887" y2="112.215174" id="Stroke-161" stroke="#761113" strokeWidth="0.25" strokeLinejoin="round"></line>
                      <line x1="359.221525" y1="142.619295" x2="352.466453" y2="145.317371" id="Stroke-163" stroke="#761113" strokeWidth="0.25" strokeLinejoin="round"></line>
                      <line x1="372.391282" y1="99.1345615" x2="365.470068" y2="107.277331" id="Stroke-165" stroke="#761113" strokeWidth="0.25" strokeLinejoin="round"></line>
                      <line x1="353.143176" y1="120.365629" x2="351.117059" y2="127.282735" id="Stroke-167" stroke="#761113" strokeWidth="0.25" strokeLinejoin="round"></line>
                      <line x1="380.832083" y1="128.79924" x2="382.124745" y2="136.889423" id="Stroke-169" stroke="#761113" strokeWidth="0.25" strokeLinejoin="round"></line>
                      <path d="M344.963339,131.183417 C344.963339,132.101653 344.217728,132.84595 343.301923,132.84595 C342.382066,132.84595 341.640508,132.101653 341.640508,131.183417 C341.640508,130.269226 342.382066,129.524929 343.301923,129.524929 C344.217728,129.524929 344.963339,130.269226 344.963339,131.183417" id="Fill-171" fill="#89CBE3"></path>
                      <path d="M359.921751,92.8695238 C359.921751,93.5976403 359.330125,94.1882237 358.600723,94.1882237 C357.871321,94.1882237 357.279695,93.5976403 357.279695,92.8695238 C357.279695,92.1414074 357.871321,91.550824 358.600723,91.550824 C359.330125,91.550824 359.921751,92.1414074 359.921751,92.8695238" id="Fill-173" fill="#89CBE3"></path>
                      <path d="M391.92426,137.057294 C391.92426,138.17374 391.012507,139.07984 389.894091,139.07984 C388.779727,139.07984 387.867975,138.17374 387.867975,137.057294 C387.867975,135.940849 388.779727,135.034749 389.894091,135.034749 C391.012507,135.034749 391.92426,135.940849 391.92426,137.057294" id="Fill-175" fill="#89CBE3"></path>
                      <path d="M383.622045,141.665463 C383.622045,142.256046 383.143882,142.733367 382.548203,142.733367 C381.96063,142.733367 381.482466,142.256046 381.482466,141.665463 C381.482466,141.074879 381.96063,140.597558 382.548203,140.597558 C383.143882,140.597558 383.622045,141.074879 383.622045,141.665463" id="Fill-177" fill="#EE3F2E"></path>
                      <path d="M370.800375,90.0331057 C370.800375,90.8704396 370.1196,91.550015 369.280788,91.550015 C368.441976,91.550015 367.761201,90.8704396 367.761201,90.0331057 C367.761201,89.1957717 368.441976,88.5161963 369.280788,88.5161963 C370.1196,88.5161963 370.800375,89.1957717 370.800375,90.0331057" id="Fill-179" fill="#EE3F2E"></path>
                      <path d="M351.117059,103.685289 C351.117059,104.336549 350.586217,104.866456 349.933807,104.866456 C349.281398,104.866456 348.754608,104.336549 348.754608,103.685289 C348.754608,103.03403 349.281398,102.504123 349.933807,102.504123 C350.586217,102.504123 351.117059,103.03403 351.117059,103.685289" id="Fill-181" fill="#EE3F2E"></path>
                      <path d="M362.960926,149.192569 C362.960926,150.029903 362.28015,150.709478 361.441338,150.709478 C360.602526,150.709478 359.921751,150.029903 359.921751,149.192569 C359.921751,148.355235 360.602526,147.67566 361.441338,147.67566 C362.28015,147.67566 362.960926,148.355235 362.960926,149.192569" id="Fill-183" fill="#EE3F2E"></path>
                      <path d="M395.691621,119.90732 C395.691621,120.489813 395.221562,120.963089 394.633988,120.963089 C394.054518,120.963089 393.580407,120.489813 393.580407,119.90732 C393.580407,119.324827 394.054518,118.855596 394.633988,118.855596 C395.221562,118.855596 395.691621,119.324827 395.691621,119.90732" id="Fill-185" fill="#EE3F2E"></path>
                      <line x1="386.74429" y1="97.1120158" x2="380.832083" y2="99.1345615" id="Stroke-187" stroke="#761113" strokeWidth="0.25" strokeLinejoin="round"></line>
                    </g>
                  </g>
                </StyledSVG>
                <LootieWrapper>
                  <Lottie options={lottieOptions(head)} />
                </LootieWrapper>
                <LootieWrapper>
                  <Lottie options={lottieOptions(mug)} />
                </LootieWrapper>
              </ImageIllustration>
              <HugeTextWrapper>
                <motion.div style={{ x: x2, display: 'flex' }}>
                  {['Content', 'Blog', 'Offers', 'Animations'].map((name) => (
                    <>
                      <HugeTextNumber>
                        {t(`Home page:Offer:${name} number`)}
                      </HugeTextNumber>
                      <HugeText>
                        {t(`Home page:Offer:${name}1`)}
                        <br/>
                        {t(`Home page:Offer:${name}2`)}
                      </HugeText>
                    </>
                  ))}
                </motion.div>
              </HugeTextWrapper>
            </RightBox>
          </Wrapper>
        )}</Sticky>
      </StyledStickyContainer>
    </StickyWrapper>
  );
};

export default HeroMore;
