import React from "react";
import BackgroundImage from "gatsby-background-image";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";
import Img from "gatsby-image";

const Wrapper = tw.div`flex flex-wrap mx-30 mb-70 lg:mx-0`;

const Title = tw.div`
  relative w-full text-xl mb-10 leading-33 -tracking-016
  lg:text-4xl lg:leading-60 lg:mb-58 lg:-tracking-028
`;

const ColumnFirst = styled(motion.div)`
  ${tw`
    w-full mb-20 leading-24 -tracking-024
    md:w-1/2 md:pr-10
    lg:text-2xl lg:leading-48
    xl:whitespace-pre-line
  `}
  
  & span {
    ${tw`text-portfolioText`}
  }
`;

const ColumnSecond = styled(motion.div)`
  ${tw`
    w-full text-second text-sm leading-20 -tracking-021
    md:w-1/2 md:pl-60
    lg:pl-110 lg:text-md lg:leading-30 lg:-tracking-027
    xl:pr-15
  `}
  
  & br {
    ${tw`hidden lg:block`}
  }
`;

const WrapperImages = tw(motion.div)`
  w-full relative mt-20 pb-170
  md:mt-40 md:order-last
  lg:mt-60 lg:pb-55
`;

const Background = tw(BackgroundImage)`h-320 -mx-30 md:mx-0 lg:h-420`;

const Illustration = tw(Img)`
  absolute! w-230 bottom-0 -right-30
  lg:-right-200 lg:w-400
`;

const Mask = styled(motion.span)`
  ${tw`hidden lg:block lg:absolute lg:inset-0 lg:bg-white`}
`;

const Communication = ({ scroll, width }: { scroll: any, width: number }) => {
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      background: file(relativePath: { eq: "offer/background.png" }) {
        childImageSharp {
          fluid(maxWidth: 1500) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      illustration: file(relativePath: { eq: "offer/communication.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const left = useTransform(scroll, [sectionStart + 50, sectionStart + 500], [0, 330]);

  const y = useTransform(scroll, [sectionStart + 150, sectionStart + 600], [150, 0]);
  const rotate = useTransform(scroll, [sectionStart + 150, sectionStart + 600], [-9, 0]);
  const opacity = useTransform(scroll, [sectionStart + 150, sectionStart + 600], [0, 1]);
  const x = useTransform(scroll, [sectionStart + 150, sectionStart + 600], [120, 0]);

  const opacityA = useTransform(scroll, [sectionStart + 650, sectionStart + 1000], [0, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref}>
      <Title>
        {t('Offer:Communication:Title')}
        <Mask style={ width >= 1024 && { left }} />
      </Title>
      <ColumnFirst style={ width >= 1024 && { y, opacity, rotate }}>
        {t('Offer:Communication:Column first1')}
        <span>{t('Offer:Communication:Column first2')}</span>
        {t('Offer:Communication:Column first7')}
        <span>{t('Offer:Communication:Column first3')}</span>
        {t('Offer:Communication:Column first7')}
        <span>{t('Offer:Communication:Column first4')}</span>
        {t('Offer:Communication:Column first7')}
        <span>{t('Offer:Communication:Column first5')}</span>
        {t('Offer:Communication:Column first7')}
        <span>{t('Offer:Communication:Column first6')}</span>
        {t('Offer:Communication:Column first8')}
      </ColumnFirst>
      <WrapperImages style={ width >= 1024 && { opacity: opacityA }}>
        <Background Tag="div" fluid={data.background.childImageSharp.fluid} />
        <Illustration fluid={data.illustration.childImageSharp.fluid} />
      </WrapperImages>
      <ColumnSecond style={ width >= 1024 && { x, opacity }}>
        {t('Offer:Communication:Column second1')}
        <br/><br/>
        {t('Offer:Communication:Column second2')}
      </ColumnSecond>
    </Wrapper>
  );
};

export default Communication;
