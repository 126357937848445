import React from "react";
import ArrowDotted from "@images/arrow-dotted.svg";
import { useTranslation } from "react-i18next";
import { up } from "styled-breakpoints";
import styled from "styled-components";
import tw from "twin.macro";
import DotSvg from "@images/dot.svg";
import DotSmallSvg from "@images/dot-small.svg";

interface StageProps {
  readonly flex: boolean,
  readonly flexReverse: boolean,
  readonly noMarginBottom: boolean,
  readonly marginLeftAuto: boolean,
  readonly mdOrder: boolean,
  readonly xlOrder: boolean
};

interface WrapperDurationProps {
  readonly marginExtra: boolean,
  readonly marginNegative: boolean,
  readonly reverse: boolean,
  readonly whitespace: boolean
};

interface StepsProps {
  readonly flex: boolean,
  readonly marginTop: boolean,
  readonly marginBottom: boolean,
  readonly marginLeft: boolean,
  readonly textRight: boolean
};

interface StepArrowProps {
  readonly top: boolean,
  readonly mdHidden: boolean,
  readonly xlHidden: boolean,
  readonly hiddenDots: boolean
};

const Stage = styled.div<StageProps>`
  ${tw`md:mb-20`}
  
  ${({ flex }) => flex ? tw`xl:flex xl:items-start` : ''}
  ${({ flexReverse }) => flexReverse ? tw`xl:flex-row-reverse xl:mt-0` : ''}
  ${({ noMarginBottom }) => noMarginBottom ? tw`xl:mb-0` : tw`xl:-mb-50`}
  ${({ marginLeftAuto }) => marginLeftAuto ? tw`md:ml-auto xl:ml-10` : ''}
  
  ${up('md')} {
     order: ${({ mdOrder }) => mdOrder ? mdOrder : '1'};
  }

  ${up('xl')} {
    order: ${({ xlOrder }) => xlOrder ? xlOrder : '1'};
  }
`;

const WrapperDuration = styled.div<WrapperDurationProps>`
  ${tw`flex items-center mb-8 px-10 xl:px-0`}

  ${({ marginExtra }) => marginExtra ? tw`xl:mr-20` : ''}
  ${({ marginNegative }) => marginNegative ? tw`-mt-8 md:mt-0` : ''}
  ${({ reverse }) => reverse ? tw`xl:flex-row-reverse xl:ml-20 xl:mr-0` : ''}
  ${({ whitespace }) => whitespace ? tw`-mt-20 md:mt-0 xl:h-35 xl:ml-30 xl:mb-0 xl:whitespace-no-wrap` : ''}
`;

const Duration = tw.div`
  font-pangramBold text-md text-second leading-30 -tracking-027
  xl:whitespace-no-wrap
`;

const Dot = styled(DotSmallSvg)<{ hiddenX: boolean }>`
  ${tw`w-12 mt-4 ml-4 mr-20`}

  ${({ hiddenX }) => hiddenX ? tw`xl:hidden` : ''}
`;

const DotLarge = styled(DotSvg)<{ marginExtra: boolean, minWidth: boolean }>`
  ${tw`w-20 mr-20 xl:mr-15`}
  
  ${({ marginExtra }) => marginExtra ? tw`xl:ml-15 xl:mr-0` : ''}
  ${({ minWidth }) => minWidth ? tw`xl:min-w-20` : ''}
`;

const Steps = styled.div<StepsProps>`
  ${tw`px-10 xl:px-0`}
  
  ${({ flex }) => flex ? tw`xl:flex-1 xl:flex xl:justify-between` : ''}
  ${({ marginTop }) => marginTop ? tw`xl:mt-6` : ''}
  ${({ marginBottom }) => marginBottom ? tw`xl:mb-0` : ''}
  ${({ marginLeft }) => marginLeft ? tw`xl:-ml-100` : ''}
  ${({ textRight }) => textRight ? tw`xl:text-right` : ''}
`;

const Step = tw.div`flex items-start`;

const StepDesc = styled.div<{ whitespace: boolean }>`
  ${tw`text-sm text-second leading-20 -tracking-021`}
  
  ${({ whitespace }) => whitespace ? tw`xl:whitespace-no-wrap` : ''}
`;

const StepArrow = styled(ArrowDotted)<StepArrowProps>`
  ${tw`
    relative -left-3 w-26 mb-8 transform rotate-90
    xl:static xl:w-66 xl:mt-5 xl:rotate-0
  `}

  top: ${({ top }) => top ? `${top}px` : '-8px'};
  ${({ mdHidden }) => mdHidden ? tw`md:hidden` : ''}
  ${({ xlHidden }) => xlHidden ? tw`xl:hidden` : ''}
  
  .dots {
    ${({ hiddenDots }) => hiddenDots ? tw`hidden` : ''}
  }
`;

const StepArrowCustom = tw(StepArrow)`hidden xl:block`;

const StepArrowLeft = styled(StepArrowCustom)`
  && {
    ${tw`ml-40 mr-10 transform rotate-180`}
  }
`;

const StepArrowDown = styled(StepArrowCustom)`
  && {
    ${tw`h-66 my-26 ml-50 mr-0 transform rotate-90`}
  }
`;

const ProcessStages = () => {
  const { t } = useTranslation();

  return (
    <>
      <Stage flex noMarginBottom mdOrder={ 1 } xlOrder={ 1 } className="md:w-1/2 xl:w-3/4">
        <WrapperDuration marginExtra className="1">
          <DotLarge />
          <Duration>
            {t('Offer:Process:Steps:1 day')}
          </Duration>
        </WrapperDuration>
        <Steps flex marginTop>
          <Step>
            <Dot hiddenX />
            <StepDesc>
              {t('Offer:Process:Steps:Order1')}
              <br/>
              {t('Offer:Process:Steps:Order2')}
            </StepDesc>
          </Step>
          <StepArrow />
          <Step>
            <Dot hiddenX />
            <StepDesc>
              {t('Offer:Process:Steps:Details1')}
              <br/>
              {t('Offer:Process:Steps:Details2')}
            </StepDesc>
          </Step>
          <StepArrow />
          <Step>
            <Dot hiddenX />
            <StepDesc className="xl:mr-20">
              {t('Offer:Process:Steps:Analysis')}
            </StepDesc>
          </Step>
          <StepArrow hiddenDots mdHidden top={ 4 } />
        </Steps>
      </Stage>
      <Stage flex noMarginBottom mdOrder={ 3 } xlOrder={ 2 } className="md:w-1/2 xl:w-1/4">
        <WrapperDuration marginExtra>
          <DotLarge />
          <Duration>
            {t('Offer:Process:Steps:3-5 days')}
          </Duration>
        </WrapperDuration>
        <Steps marginTop>
          <Step>
            <Dot hiddenX />
            <StepDesc whitespace>
              {t('Offer:Process:Steps:First look1')}
              <br/>
              {t('Offer:Process:Steps:First look2')}
              <br/>
              {t('Offer:Process:Steps:First look3')}
            </StepDesc>
          </Step>
          <StepArrow mdHidden top={ -28 } />
          <StepArrow hiddenDots mdHidden top={ -18 } />
        </Steps>
      </Stage>
      <Stage mdOrder={ 2 } xlOrder={ 5 } className="md:w-1/2 xl:w-1/12">
        <StepArrowDown />
        <WrapperDuration whitespace>
          <DotLarge minWidth />
          <Duration>
            {t('Offer:Process:Steps:3-5 days')}
          </Duration>
        </WrapperDuration>
        <StepArrowDown />
        <Steps marginLeft>
          <Step className="xl:w-290 xl:ml-20">
            <StepArrowLeft />
            <Dot hiddenX />
            <StepDesc className="xl:min-w-120">
              {t('Offer:Process:Steps:Comments1')}
              <br/>
              {t('Offer:Process:Steps:Comments2')}
            </StepDesc>
          </Step>
          <StepArrow hiddenDots xlHidden top={ 4 } />
        </Steps>
      </Stage>
      <Stage
        flex flexReverse noMarginBottom mdOrder={ 4 } xlOrder={ 7 }
        className="md:w-1/2 md:-mt-100 xl:w-5/12"
      >
        <WrapperDuration reverse>
          <DotLarge marginExtra />
          <Duration>
            {t('Offer:Process:Steps:1-2 days')}
          </Duration>
        </WrapperDuration>
        <Steps marginTop marginBottom textRight>
          <Step>
            <Dot hiddenX />
            <StepArrowLeft />
            <StepDesc className="xl:ml-20" whitespace>
              {t('Offer:Process:Steps:Improvements1')}
              <br/>
              {t('Offer:Process:Steps:Improvements2')}
            </StepDesc>
            <StepArrowLeft />
          </Step>
          <StepArrow hiddenDots xlHidden />
        </Steps>
      </Stage>
      <Stage
        flex flexReverse noMarginBottom marginLeftAuto mdOrder={ 5 } xlOrder={ 6 }
        className="md:w-1/2 md:-mt-98 xl:w-5/12"
      >
        <WrapperDuration reverse marginNegative>
          <DotLarge marginExtra />
          <Duration>
            {t('Offer:Process:Steps:1-2 days')}
          </Duration>
        </WrapperDuration>
        <Steps marginTop marginBottom className="mb-30">
          <Step>
            <Dot hiddenX />
            <StepDesc>
              {t('Offer:Process:Steps:Acceptance1')}
              <br/>
              {t('Offer:Process:Steps:Acceptance2')}
            </StepDesc>
            <StepArrowLeft />
          </Step>
        </Steps>
      </Stage>
    </>
  );
};

export default ProcessStages;
