import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

type StepContentTypes = {
  content: string,
  lighter?: boolean
};

const Text = styled.div<{ lighter: boolean }>`
  ${tw`lg:text-md lg:leading-30 lg:-tracking-027`}

  ${({ lighter }) => lighter ?
    tw`text-sm text-second leading-20 -tracking-021` :
    tw`leading-24 -tracking-024`
  }
`;

const StepColumn = ({ content, lighter }: StepContentTypes) => {
  const { t } = useTranslation();

  return (
    <Text lighter={lighter}>
      {t(`${content}`)}
    </Text>
  );
};

export default StepColumn;
