import React from "react";
import styled, { keyframes } from "styled-components";
import tw from "twin.macro";

const opacity = keyframes`
  0% { opacity: 0; }
  3% { opacity: 0; }
  12.2% { opacity: 1; }
  66% { opacity: 1; }
  72.2% { opacity: 0; }
  100% { opacity: 0; }
`;

const StyledSVG = styled.svg`
  ${tw`absolute top-0 left-0`}
  
  g > * {
    opacity: 0;
    animation: ${opacity} 6.4s infinite;
  }
`;

const Illustration = () => (
  <StyledSVG viewBox="0 0 457 402">
    <g className="offer" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(57.503311, 0.873519)">
      <line x1="109.654883" y1="72.6731356" x2="115.792605" y2="52.1469555" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
      <line x1="115.793505" y1="75.9495871" x2="133.867704" y2="62.4115568" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
      <line x1="122.579509" y1="84.6394367" x2="142.944761" y2="79.7277826" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
      <path d="M127.604083,60.7098583 C127.604083,61.4745651 126.983652,62.0941892 126.21795,62.0941892 C125.449222,62.0941892 124.828792,61.4745651 124.828792,60.7098583 C124.828792,59.9451516 125.449222,59.3255275 126.21795,59.3255275 C126.983652,59.3255275 127.604083,59.9451516 127.604083,60.7098583" fill="#00A1D6" />
      <path d="M162.591824,20.2831666 C162.591824,21.9546403 161.238983,23.305723 159.565334,23.305723 C157.894712,23.305723 156.538844,21.9546403 156.538844,20.2831666 C156.538844,18.6116929 157.894712,17.2606102 159.565334,17.2606102 C161.238983,17.2606102 162.591824,18.6116929 162.591824,20.2831666" fill="#EE3F2E" />
      <line x1="156.225607" y1="46.730535" x2="145.139574" y2="29.8556021" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
      <path d="M162.780979,64.0156291 C162.780979,64.9012382 162.063701,65.6206066 161.173913,65.6206066 C160.287151,65.6206066 159.566847,64.9012382 159.566847,64.0156291 C159.566847,63.1300201 160.287151,62.4106516 161.173913,62.4106516 C162.063701,62.4106516 162.780979,63.1300201 162.780979,64.0156291" fill="#00A1D6" />
      <path d="M184.4334,67.8884298 C184.4334,69.1397681 183.416499,70.1553471 182.163532,70.1553471 C180.910566,70.1553471 179.893665,69.1397681 179.893665,67.8884298 C179.893665,66.6370914 180.910566,65.6215125 182.163532,65.6215125 C183.416499,65.6215125 184.4334,66.6370914 184.4334,67.8884298" fill="#EE3F2E" />
      <line x1="174.597307" y1="39.4250168" x2="180.771347" y2="54.0360547" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
      <line x1="219.743447" y1="16.7558439" x2="202.34113" y2="29.8556036" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
      <g transform="translate(187.642384, 0.000000)">
        <path d="M7.47543037,4.8366948 C7.47543037,6.32379256 6.27088734,7.52979257 4.78185425,7.52979257 C3.29282117,7.52979257 2.08827814,6.32379256 2.08827814,4.8366948 C2.08827814,3.3526196 3.29282117,2.14661958 4.78185425,2.14661958 C6.27088734,2.14661958 7.47543037,3.3526196 7.47543037,4.8366948" fill="#00A1D6" mask="url(#mask-242)" />
      </g>
      <line x1="299.694235" y1="70.1553471" x2="304.23397" y2="54.0360533" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
      <path d="M328.697089,52.146956 C328.697089,55.1362642 326.266818,57.5603544 323.273619,57.5603544 C320.28042,57.5603544 317.853175,55.1362642 317.853175,52.146956 C317.853175,49.1546252 320.28042,46.730535 323.273619,46.730535 C326.266818,46.730535 328.697089,49.1546252 328.697089,52.146956" fill="#EE3F2E" />
      <path d="M373.050923,70.2822943 C373.050923,71.6031514 371.979545,72.6731364 370.653942,72.6731364 C369.331366,72.6731364 368.256962,71.6031514 368.256962,70.2822943 C368.256962,68.9614373 369.331366,67.8884298 370.653942,67.8884298 C371.979545,67.8884298 373.050923,68.9614373 373.050923,70.2822943" fill="#00A1D6" />
      <path d="M349.282375,108.421214 C349.282375,110.026191 347.974931,111.331936 346.364838,111.331936 C344.757772,111.331936 343.450328,110.026191 343.450328,108.421214 C343.450328,106.813214 344.757772,105.50747 346.364838,105.50747 C347.974931,105.50747 349.282375,106.813214 349.282375,108.421214" fill="#00A1D6" />
      <line x1="351.691159" y1="95.8470767" x2="372.328794" y2="93.8310316" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
      <line x1="358.710791" y1="111.33163" x2="368.256341" y2="99.6826977" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
      <line x1="374.34564" y1="118.132391" x2="396.541919" y2="113.98242" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
      <path d="M385.576644,130.352578 C385.576644,132.163089 384.108796,133.629029 382.292902,133.629029 C380.480035,133.629029 379.012187,132.163089 379.012187,130.352578 C379.012187,128.539044 380.480035,127.073105 382.292902,127.073105 C384.108796,127.073105 385.576644,128.539044 385.576644,130.352578 Z" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
      <polygon fill="#EE3F2E" points="382.337404 142.850242 370.636994 158.990692 385.575748 164.455474" />
      <path d="M347.612653,187.034274 C347.612653,187.034274 359.043706,183.661101 365.810938,177.247236 C376.56103,167.046108 379.012487,156.802665 379.012487,156.802665" stroke="#761113" stroke-width="0.25" stroke-linejoin="round" />
    </g>
  </StyledSVG>
);

export default Illustration;
