import React from "react";
import { motion, useTransform } from "framer-motion";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

const Heading = styled(motion.div)`
  ${tw`
    flex justify-center items-center mt-5 mb-15 text-xl leading-33 -tracking-016
    md:text-2xl
    lg:relative lg:mt-20 lg:mb-10 lg:text-8xl lg:leading-80 lg:-tracking-037
  `}
  
  span {
    ${tw`
      text-icon
      md:text-8xl
      lg:mb-4 lg:mr-10
    `}
  }
`;

const Description = tw(motion.div)`
  max-w-200 mb-30 mx-auto text-center leading-24 -tracking-024
  md:max-w-none md:mb-78 md:text-xl
  lg:relative lg:text-2xl lg:leading-48
`;

const Header = ({ scroll, width }: { scroll: any, width: number }) => {
  const { t } = useTranslation();

  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const y = useTransform(scroll, [sectionStart, sectionStart + 300], [150, 0]);
  const rotate = useTransform(scroll, [sectionStart, sectionStart + 300], [-9, 0]);
  const opacity = useTransform(scroll, [sectionStart, sectionStart + 300], [0, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <>
      <Heading ref={Ref} style={ width >= 1024 && { y, rotate, opacity }}>
        <span>{t('Offer:How we work1')}</span>
        {t('Offer:How we work2')}
      </Heading>
      <Description style={ width >= 1024 && { y, rotate, opacity }}>
        {t('Offer:Extra info about cooperation')}
      </Description>
    </>
  );
};

export default Header;
