import React from "react";
import { useTranslation } from "react-i18next";
import { down } from "styled-breakpoints";
import { motion } from "framer-motion";
import styled from "styled-components";
import tw from "twin.macro";

import Details from "@components/svg/details-simple";

const Wrapper = tw.div`relative w-full mr-20 md:w-1/2 md:mr-0 lg:w-7/12`;

const Header = styled(motion.div)`
  ${tw`
    ml-20 mb-10 text-xl leading-33 -tracking-016
    sm:ml-0
    md:mt-140 md:mb-60 md:ml-10 md:text-2xl
    lg:relative lg:top-120 lg:mt-165 lg:ml-50 lg:mb-140 lg:text-8xl lg:leading-80 lg:-tracking-037
    lg:opacity-0
  `}
  
  ${down('md')} {
    transform: none !important;
  }
`;

const Description = styled(motion.div)`
  ${tw`
    flex ml-20 mb-14 text-sm text-second leading-20 -tracking-021
    sm:ml-0
    lg:relative lg:top-240 lg:max-w-500 lg:text-2xl lg:text-primary lg:leading-60 lg:-tracking-024
    lg:opacity-0
  `}
  
  ${down('md')} {
    transform: none !important;
  }
`;

const DescriptionSpan = tw.span`
  font-pangramBold block mr-16 leading-32
  lg:mr-30 lg:text-lg lg:text-second lg:leading-50 lg:-tracking-03
`;

const HeroHeader = ({ width }: { width: number }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Details />
      <Header
        initial={ width >= 1024 && 'hidden'}
        animate={ width >= 1024 && 'visible' }
        transition={{
          y: { duration: .917, delay: .4, ease: [.88, .14, .12, .86] },
          opacity: { duration: .458, delay: .65, ease: 'linear' },
          rotate: { duration: .917, delay: .4, ease: [.88, .14, .12, .86] }
        }}
        variants={{
          'visible': { y: -120, opacity: 1, rotate: 0 },
          'hidden': { rotate: -8 }
        }}
      >
        {t('Offer:Offer')}
      </Header>
      <Description
        animate={ width >= 1024 && 'visible'}
        transition={{
          opacity: { duration: .542, ease: 'linear' },
          default: { duration: .875, delay: .542, ease: [.84, 0, .16, 1] }
        }}
        variants={{ 'visible': { y: -280, opacity: 1 } }}
      >
        <DescriptionSpan>
          {t('Description Character')}
        </DescriptionSpan>
        {t('Offer:Description')}
      </Description>
    </Wrapper>
  );
};

export default HeroHeader;
