import React from "react";
import tw from "twin.macro";

import StepStickyContainer from "@components/offer/cooperation-details/sticky-container";
import StepsWrapper from "@components/offer/cooperation-details/steps-wrapper";

const OfferStepsWrapper = tw.div`relative flex flex-wrap`;

const CooperationDetails = ({ scroll, width }: { scroll: any, width: number }) => (
  <OfferStepsWrapper>
    <StepStickyContainer />
    <StepsWrapper width={ width } scroll={ scroll } />
  </OfferStepsWrapper>
);

export default CooperationDetails;
