import React from "react";
import { motion, useTransform } from "framer-motion";
import tw from "twin.macro";

import StepColumn from "@components/offer/cooperation-details/step-column";

const WrapperContentLighter = tw(motion.div)`
  mb-30 lg:w-11/12 lg:mb-50 lg:pr-35 xl:whitespace-pre-line
`;

const WrapperContent = tw(motion.div)`mb-40 lg:w-11/12 lg:mb-50 lg:pr-35`;

const Columns = ({ scroll, width }: { scroll: any, width: number }) => {
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const y = useTransform(scroll, [sectionStart + 60, sectionStart + 460], [150, 0]);
  const rotate = useTransform(scroll, [sectionStart + 60, sectionStart + 460], [-9, 0]);
  const opacity = useTransform(scroll, [sectionStart + 60, sectionStart + 460], [0, 1]);

  const stepOpacity = useTransform(scroll, [sectionStart + 50, sectionStart + 350], [0, 1]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <>
      <WrapperContentLighter ref={Ref} style={ width >= 1024 && { opacity: stepOpacity }}>
        <StepColumn lighter content={'Offer:Steps:Naming:First Column'} />
      </WrapperContentLighter>
      <WrapperContent style={ width >= 1024 && { y, rotate, opacity }}>
        <StepColumn content={'Offer:Steps:Naming:Second Column'} />
      </WrapperContent>
    </>
  );
};

export default Columns;
