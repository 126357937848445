import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { motion, useViewportScroll } from "framer-motion";
import useWindowSize from "@utils/useWindowSize";

import Layout from '@layouts/index';
import SEO from "@components/seo";
import Hero from "@components/offer/hero";
import HeroMore from "@components/offer/hero/more";
import CooperationHeader from "@components/offer/cooperation-details/header";
import CooperationDetails from "@components/offer/cooperation-details";
import HowWeWork from "@components/offer/how-we-work/header";
import Process from "@components/offer/how-we-work/process";
import Communication from "@components/offer/how-we-work/communication";
import Presentation from "@components/offer/how-we-work/presentation";
import Formalities from "@components/offer/how-we-work/formalities";
import CTA from "@components/cta";

const OfferPage = () => {
  const intl = useIntl();
  const { scrollY } = useViewportScroll();
  const { width } = useWindowSize();

  return (
    <Layout>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: 'SEO.Title.Offer' })}
        description={intl.formatMessage({ id: 'SEO.Description.Offer' })}
      />
      <Hero width={width} />
      <motion.div
        className="lg:opacity-0"
        animate={ width >= 1024 && { opacity: 1 }}
        transition={{
          duration: .6,
          delay: 1.2,
          ease: [0.13, -0.22, 1, 1.02]
        }}
      >
        <HeroMore width={width} scroll={scrollY} />
      </motion.div>
      <CooperationHeader width={width} scroll={scrollY} />
      <CooperationDetails width={ width } scroll={ scrollY } />
      <HowWeWork width={ width } scroll={ scrollY } />
      <Process width={ width } scroll={ scrollY } />
      <Communication width={ width } scroll={ scrollY } />
      <Presentation width={ width } scroll={ scrollY } />
      <Formalities width={ width } scroll={ scrollY } />
      <CTA width={ width } scroll={ scrollY } />
    </Layout>
  );
};

export default OfferPage;
