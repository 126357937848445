import React from "react";
import { motion, useTransform } from "framer-motion";
import { graphql, useStaticQuery } from "gatsby";
import { pagesRoutes } from "@utils/variables";
import Img from "gatsby-image";
import tw from "twin.macro";

import StepColumn from "@components/offer/cooperation-details/step-column";
import LinkPrimary from "@components/links/primary";
import ArrowRed from "@components/arrows/red";

const ColumnsWrapper = tw.div`flex flex-wrap`;

const Column = tw(motion.div)`w-full md:w-1/2 md:order-first lg:relative`;

const ColumnMain = tw(Column)`md:pr-60 xl:whitespace-pre-line`;

const ColumnWithSpace = tw(Column)`mb-30 md:pr-30 xl:whitespace-pre-line`;

const Button = tw(motion.div)`w-full mb-50 md:w-1/2 md:mt-60 lg:relative`;

const Images = tw(motion.div)`relative w-full md:w-1/2 md:order-last`;

const Illustration = tw(Img)`lg:-left-45`;

const ArrowWrapper = tw.div`
  absolute top-40 -left-55 w-220
  lg:top-68 lg:-left-50 lg:w-300
`;

const Columns = ({ scroll, width }: { scroll: any, width: number }) => {
  const data = useStaticQuery(graphql`
    query {
      illustration: file(relativePath: { eq: "offer/illustration.png" }) {
        childImageSharp {
          fluid(maxWidth: 500) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const top = useTransform(scroll, [sectionStart, sectionStart + 350], [200, 0]);
  const opacity = useTransform(scroll, [sectionStart, sectionStart + 350], [0, 1]);

  const left = useTransform(scroll, [sectionStart + 400, sectionStart + 600], [-100, 0]);
  const btnOpacity = useTransform(scroll, [sectionStart + 400, sectionStart + 600], [0, 1]);

  const illustrationOpacity = useTransform(scroll, [sectionStart + 400, sectionStart + 650], [0, 1]);
  const illustrationTop = useTransform(scroll, [sectionStart + 400, sectionStart + 650], [150, 0]);
  const illustrationLeft = useTransform(scroll, [sectionStart + 400, sectionStart + 650], [-100, 0]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <ColumnsWrapper ref={Ref}>
      <ColumnMain style={ width >= 1024 && { top, opacity }}>
        <StepColumn lighter content={'Offer:Steps:Communication:First Column'} />
      </ColumnMain>
      <Images
        style={ width >= 1024 && {
          opacity: illustrationOpacity,
          top: illustrationTop,
          left: illustrationLeft
        }}
      >
        <ArrowWrapper>
          <ArrowRed />
        </ArrowWrapper>
        <Illustration fluid={data.illustration.childImageSharp.fluid} />
      </Images>
      <ColumnWithSpace style={ width >= 1024 && { top, opacity }}>
        <StepColumn lighter content={'Offer:Steps:Communication:Second Column'} />
      </ColumnWithSpace>
      <Button style={ width >= 1024 && { left, opacity: btnOpacity }}>
        <LinkPrimary text={'Buttons:Case Studies'} path={pagesRoutes.portfolio} />
      </Button>
    </ColumnsWrapper>
  );
};

export default Columns;
