import React from "react";
import { createOfferStepsId } from "@utils/helpers";
import scrollTo from 'gatsby-plugin-smoothscroll';
import { useTranslation } from "react-i18next";
import { offerSteps } from "@utils/variables";
import Scrollspy from "react-scrollspy";
import styled from "styled-components";
import tw from "twin.macro";

const handleClick = (e: MouseEvent, name: string) => {
  e.preventDefault();
  scrollTo(createOfferStepsId(name));
};

const Nav = tw(Scrollspy)`hidden lg:block lg:relative lg:w-full lg:mt-46 lg:pl-50 lg:z-1 xl:pl-80`;

const ItemWrapper = styled.a`
  transition-timing-function: ease-out;
  ${tw`
    relative hidden overflow-hidden transition-colors duration-300 delay-200
    lg:block lg:mb-15 lg:px-4 lg:py-3 lg:leading-30 lg:-tracking-024 lg:rounded-full
    lg:cursor-pointer lg:bg-white lg:text-base
  `}
  
  &::before {
    content: '';
    border-radius: 50%;
    transform-origin: 100% 50%;
    transform: scale3d(1, 2, 1);
    transition-timing-function: cubic-bezier(0.7,0,0.9,1);
    
    ${tw`
      absolute top-1/2 left-full w-30 h-30 -mt-15 ml-1 bg-portfolioText transition-all duration-300
    `}
  }
  
  &.active, &:hover {
    transition-timing-function: cubic-bezier(0.7,0,0.9,1);
    ${tw`lg:text-white transition-colors duration-300 delay-100`}
    
    &::before {
      transform: scale3d(14, 9, 1);
    }
  }
`;

const Item = tw.div`relative lg:flex lg:items-center lg:select-none z-1`;

const Number = tw.span`
  font-pangramBold -tracking-018
  lg:flex lg:justify-center lg:items-center lg:mr-18 lg:w-44 lg:h-44 lg:text-xs lg:text-primary
  lg:bg-white lg:rounded-full
`;

const StepStickyNav = () => {
  const { t } = useTranslation();

  return (
    <Nav items={offerSteps.map(step => step.name)} offset={ -10 } currentClassName="active">
      {offerSteps.map((item, index) => (
        <ItemWrapper
          onClick={(e: MouseEvent) => handleClick(e, item.name)}
          href={createOfferStepsId(item.name)}
          key={item.name}
        >
          <Item>
            <Number>{index + 1}</Number>
            {t(`Offer:Options:${item.name}`)}
          </Item>
        </ItemWrapper>
      ))}
    </Nav>
  );
};

export default StepStickyNav;
