import React from "react";
import { offerSteps } from "@utils/variables";
import tw from "twin.macro";

import Step from "@components/offer/cooperation-details/step";
import StepContent from "@components/offer/cooperation-details/step-content";

const OfferStep = tw.div`flex flex-wrap w-full`;

const StepsWrapper = ({ scroll, width }: { scroll: any, width: number }) => (
  <>
    {offerSteps.map((step, index) => (
      <OfferStep id={step.name} key={index}>
        <Step width={ width } scroll={ scroll } option={step} index={index}>
          <StepContent name={step.name} width={width} scroll={scroll} />
        </Step>
      </OfferStep>
    ))}
  </>
);

export default StepsWrapper;
