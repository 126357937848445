import React from "react";
import { motion, useTransform } from "framer-motion";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

type HeadingTypes = {
  name: string,
  number: number,
  width: number,
  scroll: any
};

const Wrapper = tw.div`
  relative flex mx-20 mb-10 pt-10
  md:mb-20
  lg:mb-35 lg:mx-0 lg:pt-30
`;

const Number = tw(motion.div)`
  min-w-30 text-portfolioText text-xl leading-25 -tracking-016
  lg:min-w-66 lg:mr-32 lg:text-10xl lg:leading-90 lg:-tracking-067
`;

const Title = styled.div`
  ${tw`
    text-md leading-25 -tracking-013
    lg:text-2xl lg:leading-48 lg:-tracking-024
  `}
  
  & span {
    ${tw`text-portfolioText underline`}
  }
`;

const Mask = styled(motion.span)`
  ${tw`hidden lg:block lg:absolute lg:inset-0 lg:left-90 lg:bg-white`}
`;

const Heading = ({ name, number, width, scroll }: HeadingTypes) => {
  const { t } = useTranslation();
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const opacity = useTransform(scroll, [sectionStart + 100, sectionStart + 300], [0, 1]);
  const left = useTransform(scroll, [sectionStart + 200, sectionStart + 400], [90, 815]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref} id={name}>
      <Number style={ width >= 1024 && { opacity }}>
        {number}.
      </Number>
      <Title>
        {t(`Offer:Steps:${name}:Title1`)}
        <span>{t(`Offer:Steps:${name}:Title2`)}</span>
        {name !== 'Communication' && <br/>}
        {t(`Offer:Steps:${name}:Title3`)}
      </Title>
      <Mask style={ width >= 1024 && { left } } />
    </Wrapper>
  );
};

export default Heading;
