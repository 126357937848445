import React from "react";
import { motion, useTransform } from "framer-motion";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import tw from "twin.macro";

const Wrapper = tw.div`
  flex flex-wrap mx-30 mb-70
  lg:mx-0 lg:mb-118
  xl:items-center
`;

const Title = tw.div`
  relative w-full mb-10 text-xl leading-33 -tracking-016
  lg:mb-58 lg:text-4xl lg:leading-60 lg:-tracking-028
`;

const ColumnFirst = styled(motion.div)`
  ${tw`
    w-full mb-20 leading-24 -tracking-024
    lg:relative lg:text-2xl lg:leading-48
    xl:w-1/2
  `}
  
  & span {
    ${tw`text-portfolioText`}
  }
`;

const ColumnSecond = styled(motion.div)`
  ${tw`
    w-full text-second text-sm leading-20 -tracking-021
    lg:relative lg:text-md lg:leading-30 lg:-tracking-027
    xl:w-1/2 xl:pl-110 xl:pr-5`}
  
  & br {
    ${tw`hidden xl:block`}
  }
`;

const Mask = styled(motion.span)`
  ${tw`hidden lg:block lg:absolute lg:inset-0 lg:bg-white`}
`;

const Formalities = ({ scroll, width }: { scroll: any, width: number }) => {
  const { t } = useTranslation();
  const Ref = React.useRef();

  const [sectionStart, setSectionStart] = React.useState(0);

  const left = useTransform(scroll, [sectionStart + 50, sectionStart + 500], [0, 330]);
  const opacity = useTransform(scroll, [sectionStart + 200, sectionStart + 600], [0, 1]);
  const columnLeft = useTransform(scroll, [sectionStart + 200, sectionStart + 600], [-100, 0]);
  const right = useTransform(scroll, [sectionStart + 200, sectionStart + 600], [-100, 0]);

  React.useLayoutEffect(() => {
    if (!Ref.current) return;

    const offsetStart = window && Ref.current.getClientRects()[0].top + window.pageYOffset - window.innerHeight;

    setSectionStart(offsetStart);
  });

  return (
    <Wrapper ref={Ref}>
      <Title>
        {t('Offer:Formalities:Title')}
        <Mask style={ width >= 1024 && { left }} />
      </Title>
      <ColumnFirst style={ width >= 1024 && { opacity, left: columnLeft }}>
        {t('Offer:Formalities:Column first1')}
        <span>{t('Offer:Formalities:Column first2')}</span>
        {t('Offer:Formalities:Column first3')}
      </ColumnFirst>
      <ColumnSecond style={ width >= 1024 && { opacity, right }}>
        {t('Offer:Formalities:Column second1')}
        <br/>
        {t('Offer:Formalities:Column second2')}
      </ColumnSecond>
    </Wrapper>
  );
};

export default Formalities;
