import React from "react";
import tw from "twin.macro";
import LongCycleSvg from "@images/long-cycle.svg";

const WrapperCycle = tw.div`relative hidden xl:block xl:order-3 xl:w-1/12`;

const Cycle = tw(LongCycleSvg)`absolute! -top-25 left-5 w-120`;

const ProcessCycle = () => (
  <WrapperCycle>
    <Cycle />
  </WrapperCycle>
);

export default ProcessCycle;
